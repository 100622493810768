<template>
	<pui-datatable :modelName="modelName"></pui-datatable>
</template>

<script>
export default {
	data() {
		return {
			modelName: 'operationsuser',
			modelColumnDefs: {
				disabled: {
					render: (data, type, row, meta) => {
						if (data === 0) {
							return this.$t('common.no');
						} else if (data === 1) {
							return this.$t('common.yes');
						} else {
							return '-';
						}
					}
				}
			}
		};
	},
	computed: {}
};
</script>
